
import './css/main.css';
import InfiniteScroll from 'infinite-scroll';



let Isotope = require('isotope-layout');

var imagesLoaded = require('imagesloaded');
InfiniteScroll.imagesLoaded = imagesLoaded;

    let isogrid = document.getElementsByClassName("iso-grid");
    if ( isogrid.length > 0 ) {
    let iso = new Isotope( '.iso-grid', {
        itemSelector: '.grid-item',
        stamp: '.stamp',
        hiddenStyle: {
          opacity: 0
        },
        percentPosition: true,
        // masonry: {
        //     columnWidth: '.grid-sizer',
        // },
        visibleStyle: {
          opacity: 1
        },
    });
    

    let path = window.location.origin + window.location.pathname;
    let searchpath = window.location.search;
    let infscrollelem = document.querySelector('.iso-grid');
    console.log(path + '/p{{#}}/' + searchpath);
    // console.log(infscrollelem);
        let infScroll = new InfiniteScroll( infscrollelem, {
        // options
        path: path + '/p{{#}}/' + searchpath  ,
        status: '.page-load-status',
        append: '.grid-item',
        history: false,
        outlayer: iso,
        checkLastPage: '#notLastPage',
        });
    
        infScroll.on( 'append', function() {
            iso.layout();
        });
};



